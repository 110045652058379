.display-image-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.info-container {
  margin-bottom: 20px;
}

.image {
  max-width: 100%;
  height: auto;
  border: 2px black dashed;
}
button {
  color: red;
}

.display-image-container button {
  background-color: red;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.display-image-container button:hover {
  background-color: rgba(214, 23, 23, 0.596);
}
