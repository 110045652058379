#login-div {
    text-align: center;


}

#login-div input[type="text"] {
    width: 100%;
    font-size: larger;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

#login-div button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}