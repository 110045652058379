/* searchPage.css */

.search-page {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-page h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.search-page input[type="text"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-page button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
}

.search-page button:hover {
  background-color: #0056b3;
}

.search-page ul {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.search-page li {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  width: 90%;
  text-align: center;
}

.search-page p {
  margin: 0;
}
