/* navbar.css */

nav {
  background-color: #f18801;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 65px;
}

nav h1 {
  margin: 0;
}

ul {
  list-style: none;
  display: flex;
  margin-right: 35px;
}

li {
  margin-right: 20px;
}

li a {
  color: #fff;
  text-decoration: none;
}

li a:hover {
  text-decoration: underline;
}
