/* uploadForm.css */

.upload-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.upload-form h1 {
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}

.upload-form label {
  display: block;
  margin-bottom: 10px;
}

.upload-form input[type="text"],
.upload-form input[type="file"] {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.upload-form button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.upload-form button:hover {
  background-color: #0056b3;
}
